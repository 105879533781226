import { render, staticRenderFns } from "./pointOfSale.vue?vue&type=template&id=4812cc69"
import script from "./pointOfSale.vue?vue&type=script&lang=js"
export * from "./pointOfSale.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports